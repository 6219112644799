<template>
    <div>
		<page-title :heading=heading :subheading=subheading></page-title>
		

		<a-card :bordered="false" class="header-solid px-5 mb-5 pb-1" :bodyStyle="{padding: 0, paddingTop: '16px', marginBottom: '90px !important'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">Expense Details</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button id="txtBtn" type="text" size="default" class="txtBtn mr-3 pt-3" style="color: #FB8C00; font-size: 16px;" @click="onEditItem">
						<a-icon type="form" /> Edit
					</a-button>
					<a-button type="primary" :href="`/pharmacy-management/drugs/view/${$route.params.uuid}`" class="">
						<a-icon type="arrow-left" theme="outlined" class="text-white mr-0" />Back
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			
			<div class="pb-0 mb-0">
			
				<a-row :gutter="24">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Category</strong></label>
						<a-input 
							disabled
							v-model="expenseDetails.category"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Amount</strong></label>
						<a-input 
							disabled
							v-model="expenseDetails.amount"
							/>
					</a-col>
				</a-row>


				<a-row :gutter="24">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Method</strong></label>
						<a-input 
							disabled
							v-model="expenseDetails.method2"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Date</strong></label>
						<a-input 
							disabled
							v-model="expenseDetails.date2"
							/>
					</a-col>
				</a-row>


				<a-row :gutter="24">

					<a-col :span="24" :md="12" class="">
						<label><strong>Description</strong></label>
						<a-input 
							disabled
							v-model="expenseDetails.description"
							/>
					</a-col>


				</a-row>

				
			</div>
		</a-card>
		
		
		<!-- <a-card :bordered="false" class="header-solid px-5 mt-5" :bodyStyle="{marginTop: '80px'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">Request Tracking</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			
			<div class="">
				<a-row type="flex" :gutter="24" class="pb-4">
					<a-col :span="24" :md="24">
						<a-table class="mt-20 pb-2"  
							size="middle"
							:responsive="true"
							:bordered="false"
							:columns="columns" 
							:data-source="records" 
							:rowKey="record => record.uuid"
							:loading="pagination.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
								</template>

								<template slot="stage" slot-scope="stage, record">
									{{record.isActive ? record.stage.nextStatus : `${ record.status[0].toUpperCase() }${ record.status.substring(1) } ${record.stage.currentStatus}` }}
								</template>

								<template slot="sellingPrice" slot-scope="sellingPrice">
									{{ sellingPrice.toLocaleString() }}
								</template>

								<template slot="totalBuyingPrice" slot-scope="totalBuyingPrice">
									{{ totalBuyingPrice.toLocaleString() }}
								</template>

								<template slot="status" slot-scope="status">
									<a-badge  
										style="font-size: 12px !important; padding: 2px 5px;" 
										:count="`${status[0].toUpperCase()}${status.substring(1)}`" 
										:number-style="status == 'approved' ? { backgroundColor: '#52C41A', color: '#FFFFFF'} : status == 'rejected' ? { backgroundColor: '#F44335', color: '#FFFFFF'} : status == 'submitted' || status == 'created' ? { backgroundColor: '#FB8C00', color: '#FFFFFF'} : { backgroundColor: '#D9D9D9', color: '#444', boxShadow: '0 0 0 1px #d9d9d9 inset'}"/>
								</template>

								<template slot="createdAt" slot-scope="createdAt">
									{{ $Moment(createdAt).format("DD MMM YYYY") }}
								</template>

								<template slot="action" slot-scope="action, record">
									<a-button v-if="record.isActive && record.stage.stageNumber == 2" id="txtBtn" type="text" class="pb-0 pt-0 txtBtn text-success" style="" @click="onLaunchTrackingModal(record)">
										<strong>Submit</strong>
									</a-button>
								</template>
							
						</a-table>
					</a-col>

				</a-row>
			</div>
		</a-card> -->


		<!-- FORM MODAL -->
		<a-modal v-model="newItem.showModal" :footer="null">
			<h4 class="text-center">{{ newItem.isCreate ? 'Add Expense' : 'Edit Expense'}}</h4>
			<a-form
				:model="newItem"
                :form="form"
                class="mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmit">

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Category"
					placeholder="Select Category"
					:colon="false">
					<a-select placeholder="Select Category" 
						name="categoryUuid"
						v-decorator="[ 'categoryUuid',
						{ rules: [{ required: true, message: 'Please select category of the expense!' }] },]">
						<a-select-option v-for="option in expenseCategories"
							:value="option.uuid"
							:key="option.name">
							{{ option.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Amount"
					:colon="false">
						<a-input 
							name="amount"
							type="number"
							v-decorator="[ 'amount',
								{ rules: [{ required: true, message: 'Please enter the amount to be spent!' }] },
							]"
							placeholder="Amount"/>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Payment Method"
					placeholder="Select Payment Method"
					:colon="false">
					<a-select placeholder="Select Payment Method" 
						name="method"
						v-decorator="[ 'method',
						{ rules: [{ required: true, message: 'Please select method of payment!' }] },]">
						<a-select-option v-for="option in methods"
							:value="option.uuid"
							:key="option.name">
							{{ option.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Date"
					:colon="false">
						<a-date-picker
							format="DD MMM YYYY"
							style="width: 100%"
							v-decorator="['date', { rules: [{ required: true, message: 'Please select the entry date!' }] }]"
							placeholder="Date" />
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Description"
					:colon="false">
						<a-textarea 
							name="description"
							v-decorator="['description', { rules: [] }]"
							placeholder="Description"/>
				</a-form-item>

				<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
					<a-button type="primary" color="#D15A1B" :loading="newItem.loading" html-type="submit" class="">
						{{ newItem.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>


		<!-- DELETE CONFIRMATION -->
		<a-modal v-model="deleteDataItem.showModal" :footer="null" width="416px">

			<a-row :gutters="24" class="">
				<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
					<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
				</a-col>

				<a-col :span="24" :md="21" :lg="21" :xl="21">

					<a-row :gutters="24">
						<a-col :span="24">
							<h6>Do you want to delete this record?</h6>
							<p>Once you delete, you will not be able to recover this inforamtion.</p>
						</a-col>
					</a-row>

					<a-row :gutters="24">
						<a-col :span="24" class="text-right">
							<a-button id="txtBtn" type="text" class="text-secondary txtBtn" @click="cancelItemDeletion">
								Cancel
							</a-button>
							&nbsp;&nbsp;
							<a-button id="txtBtn" type="text" class="text-danger txtBtn" @click="onDeleteItem">
                                Delete
							</a-button>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
      
		</a-modal>


		<!-- Tracking Modal -->
        <a-modal v-model="tracking.showModal" :footer="null">
            <h4 class="text-center">Add Comments</h4>

            <form @submit.prevent="onSubmitTracking">

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Comments"
					:colon="false">
						<a-textarea
							style="height: 60px !important;"
							name="comments"
							:rows="4"
							placeholder="Comments"
							v-model="tracking.comments"
							/>
				</a-form-item>
                
				<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
					<a-button type="primary" :loading="tracking.loading" html-type="submit" class="">
						Submit
					</a-button>
				</a-form-item>
					
            </form>
        </a-modal>

    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
			// width: "5%"
        },
		{
			title: 'STAGE NUMBER',
			dataIndex: 'stage.stageNumber',
			// //width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'STAGE',
			dataIndex: 'stage',
			scopedSlots: { customRender: 'stage' },
			// //width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'COMMENTS',
			dataIndex: 'comments',
			// width: "13%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'STATUS',
			dataIndex: 'status',
			scopedSlots: { customRender: 'status' },
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			// width: "30%"
			// align: "center"
		},
	];


    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
		components: {
			PageTitle,
		},
		data() {
			return {
            heading: 'Settings',
            subheading: 'Medicine Types',
            icon: 'pe-7s-drawer icon-gradient bg-happy-itmeo',
    
            fields: [ 'first_name', 'last_name', 'age' ],
            items: [
            { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            { age: 89, first_name: 'Geneva', last_name: 'Wilson' }
            ],
            striped: false,
            bordered: false,
            outlined: false,
            small: false,
            hover: false,
            dark: false,
            fixed: false,
            footClone: false,

            pagination: {
                search: '',
                loading: false,
                perPage: 10,
                currentPage: 1,
                perPageOptions: [
                    {label: "10", value: 10}, 
                    {label: "20", value: 20}, 
                    {label: "30", value: 30}, 
                    {label: "50", value: 50}
                ],
                total: 10
            },

            columns,
            message: '',

            records: [],
			
			expenseCategories: [],
			methods: [
				{ uuid: "cash", name: "Cash" },
				{ uuid: "bank", name: "Bank" },
				{ uuid: "nhif", name: "NHIF" },
				{ uuid: "mobile-money", name: "Mobile Money" },
			],
            
            form: '',
            rules,

			expenseDetails: {
				uuid: null,
				companyUuid: null,
				categoryUuid: null,
				category: null,
				method2: null,
				method: null,
				amount: null,
				date: null,
				date2: null,
				description: null,
				status: null,
				isCreate: true,
				loading: false,
				showModal: false,
			},

            newItem: {
                uuid: null,
                name: null,
                companyUuid: null,
                codeName: null,
                description: null,
                isCreate: true,
                loading: false,
                showModal: false,
            },

            deleteDataItem: {
                uuid: null,
                showModal: false,
            },

            userDetails: {
                user: null,
                company: null
            },

			tracking: {
				isCreate: true,
				showModal: false,
				loading: false,
				trackingUuid: null,
				status: "submitted",
				comments: "",
				nextStageNumber: 0,
			},

            notify: {
                text: null,
                type: null,
            },
        }
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'newItemForm' });
		},
      computed: {
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
        },
		watch: {
            pagination: {
                handler() {
                    this.getExpenseTrackings();
                },
            },
            deep: true,
        },
		created() {
			this.form = this.$form.createForm(this, { name: 'newItemForm' })
            this.getUserDetails();
			this.getExpenseDetails();
			this.getExpenseCategory();
			this.getTrackings();
		},
		methods: {

			onSearchItem() {
				if(this.pagination.search != null && this.pagination.search.length > 0) {
					this.getExpenseTrackings()
				}else{
					this.notify.text = "Please enter something to search"
					this.notify.type = "error"

					this.$notify(this.notify)
				}
			},

			onClearSearchItem() {
				this.pagination.search = '';

				this.getExpenseTrackings();
			},

			onCurrentPageChange(value) {
				this.pagination.currentPage = value;

				this.getExpenseTrackings();
			},

			onPerPageChange(value) {
                this.pagination.perPage = value;

                this.getExpenseTrackings();
            },


            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }
            },


			getActiveStage() {
                let show = false;
                let tracker = this.records.filter(track => track.isActive && track.stage.stageNumber == 2)

                if(tracker.length == 1) {
                    show = true;
                }

                return show
            },


			async getExpenseDetails() {
                
                let url = `${this.$BACKEND_URL}/expenses/show/${this.$route.params.uuid}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
						this.expenseDetails.uuid = response.data.uuid;
						this.expenseDetails.category = response.data.category.name;
						this.expenseDetails.categoryUuid = response.data.category.uuid;
						this.expenseDetails.date = response.data.date;
						this.expenseDetails.date2 = this.$Moment(response.data.date).format("DD MMM YYYY");
						this.expenseDetails.amount = response.data.amount;
						this.expenseDetails.method = response.data.method;
						this.expenseDetails.method2 = `${response.data.method[0].toUpperCase()}${response.data.method.substring(1)}`;
						this.expenseDetails.status = `${response.data.status[0].toUpperCase()}${response.data.status.substring(1)}`;
						this.expenseDetails.description = response.data.description;
                    }
					
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    // eslint-disable-next-line no-console
                    // console.log(error)

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                });
            },


			async getExpenseCategory() {

                const currentPage = 1;
				const perPage = 1000;

				let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                let companyUuid = userDetails.company != null ? userDetails.company.uuid : null

                let url = `${this.$BACKEND_URL}/expense-categories/${companyUuid}?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.expenseCategories = response.data.data;
                    }
					
                }).catch((error) => {
					
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "error"
                    this.$notify(this.notify)

                });
            },


			async onEditItem() {
				// this.form = this.$form.createForm(this, { name: 'newItemForm' })
				let item = this.expenseDetails;
				this.newItem.isCreate = false;
				this.newItem.showModal = true;
                this.newItem.uuid = item.uuid;
                this.newItem.companyUuid = this.userDetails.company.uuid;
				this.newItem.loading = false;
				// eslint-disable-next-line no-console
				// console.log(item)
				setTimeout(() => {
					this.form.setFieldsValue({
						categoryUuid: item.categoryUuid,
						method: item.method,
						amount: item.amount,
						date: item.date,
						description: item.description,
					});
				}, 10);

            },

			async handleSubmit() {

				this.form.validateFields((err, values) => {
					if ( !err ) {

						this.newItem.loading = true;

						// eslint-disable-next-line no-console
						console.log(this.form)
			
						let url = `${this.$BACKEND_URL}/expenses/${this.$route.params.uuid}`

						let payload = values;

						payload.date = this.$Moment(values.date._d).format("YYYY/MM/DD")

						this.$AXIOS.patch(url, payload).then(response => {

							if (response.status >= 200 && response.status < 210) {

								this.form.resetFields()

								this.newItem.uuid = null;
								this.newItem.name = null;
								this.newItem.codeName = null;
								this.newItem.description = null;
								this.newItem.isCreate = true;
								this.newItem.loading = false;
								this.newItem.showModal = false;

								this.notify.text = response.data.message
								this.notify.type = "success"

								this.$notify(this.notify)

							}

							this.getExpenseDetails()
			
						}).catch(error => {
						
							this.newItem.loading = false;
				
							if(error.response != null && error.response.status == 401) {
								this.$router.push(`/`);
							}

							this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
							this.notify.type = "error"

							this.$notify(this.notify)
						});
					}
				})
				
			},


			showDeletionConfirmation(uuid) {
				this.deleteDataItem.uuid = uuid;
				this.deleteDataItem.showModal = true;
			},

			cancelItemDeletion() {
				this.deleteDataItem.uuid = null;
				this.deleteDataItem.showModal = false;
			},

			onDeleteItem() {

				this.deleteDataItem.loading = true;

                let url = `${this.$BACKEND_URL}/drugs/${this.deleteDataItem.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteDataItem.uuid = null;
					this.deleteDataItem.showModal = false;
                
                    this.deleteDataItem.loading = false;

					this.notify.text = response.data.message;
                    this.notify.type = "success"

                    this.$notify(this.notify)

					this.getExpenseTrackings();

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.pagination.loading = false;
                });
			},



			/// TRACKINGS
			onLaunchTrackingModal(item) {
                this.tracking.trackingUuid = item.uuid;
                this.tracking.status = "submitted";
                this.tracking.comments = "";
                this.tracking.nextStageNumber = parseInt(item.stage.stageNumber) + 1;
                this.tracking.isCreate = true;
                this.tracking.showModal = true;
            },


			async getTrackings() {

                const currentPage = 1;
				const perPage = 1000;

                let url = `${this.$BACKEND_URL}/expenses/trackings/expense/${this.$route.params.uuid}?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.records = response.data.data;
                    }
					
                }).catch((error) => {
					
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "error"
                    this.$notify(this.notify)

                });
            },



			onSubmitTracking() {

				this.tracking.loading = true;

                let url = `${this.$BACKEND_URL}/expenses/approve`;

                this.$AXIOS.post(url, this.tracking).then((response) => {
                    if (response.status >= 200 && response.status < 210) {

						this.tracking.loading = false;
                        this.tracking.trackingUuid = null;
                        this.tracking.status = null;
                        this.tracking.comments = null;
                        this.tracking.nextStageNumber = null;
                        this.tracking.showModal = false;


                        this.notify.message = response.data.message;
                        this.notify.type = "success"
                        this.$notify(this.notify)
                    }

					this.tracking.loading = false;

                    this.getTrackings();
                })
                .catch((error) => {
					this.tracking.loading = false;
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "error"
                    this.$notify(this.notify)
                });
            
            },


		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>